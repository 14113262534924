import { PropertyValues } from "lit";
import { TAUIDomEvent } from "../common/dom/fire_event";
import { HapticType } from "../data/haptics";
import { Constructor, TucanoAdminUI } from "../types";
import { storeState } from "../util/taui-pref-storage";
import { TauiBaseEl } from "./taui-base-mixin";

interface VibrateParams {
  vibrate: TucanoAdminUI["vibrate"];
}

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-vibrate": VibrateParams;
  }
  // for add event listener
  interface HTMLElementEventMap {
    "taui-vibrate": TAUIDomEvent<VibrateParams>;
  }
}

const hapticPatterns = {
  success: [50, 50, 50],
  warning: [100, 50, 100],
  failure: [200, 100, 200],
  light: [50],
  medium: [100],
  heavy: [200],
  selection: [20],
};

const handleHaptic = (hapticTypeEvent: TAUIDomEvent<HapticType>) => {
  navigator.vibrate(hapticPatterns[hapticTypeEvent.detail]);
};

export const hapticMixin = <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    protected firstUpdated(changedProps: PropertyValues) {
      super.firstUpdated(changedProps);
      this.addEventListener("taui-vibrate", (ev) => {
        const vibrate = ev.detail.vibrate;
        // @ts-expect-error not all browsers support vibrate
        if (navigator.vibrate && vibrate) {
          window.addEventListener("haptic", handleHaptic);
        } else {
          window.removeEventListener("haptic", handleHaptic);
        }
        this._updateTaui({ vibrate });
        storeState(this.taui!);
      });
    }

    protected tauiConnected() {
      super.tauiConnected();
      // @ts-expect-error not all browsers support vibrate
      if (navigator.vibrate && this.taui!.vibrate) {
        window.addEventListener("haptic", handleHaptic);
      }
    }
  };
