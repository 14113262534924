import { Constructor } from "../types";
import { TauiBaseEl } from "./taui-base-mixin";

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      // @ts-ignore
      this.registerDialog({
        dialogShowEvent: "taui-notification",
        dialogTag: "notification-manager",
        dialogImport: () => import("../managers/notification-manager"),
      });
    }
  };
