import { LitElement } from "lit";
import { TucanoAdminUI } from "../../types";

export function computeRTL(taui: TucanoAdminUI, language?: string) {
  let lang = taui.language || "en";
  if (language) {
    lang = language;
  }

  if (taui.translationMetadata.translations[lang]) {
    return taui.translationMetadata.translations[lang].isRTL || false;
  }
  return false;
}

export function computeRTLDirection(taui: TucanoAdminUI) {
  return emitRTLDirection(computeRTL(taui));
}

export function emitRTLDirection(rtl: boolean) {
  return rtl ? "rtl" : "ltr";
}

export function computeDirectionStyles(isRTL: boolean, element: LitElement) {
  const direction: string = emitRTLDirection(isRTL);
  setDirectionStyles(direction, element);
}

export function setDirectionStyles(direction: string, element: LitElement) {
  element.style.direction = direction;
  element.style.setProperty("--direction", direction);
  element.style.setProperty(
    "--float-start",
    direction === "ltr" ? "left" : "right"
  );
  element.style.setProperty(
    "--float-end",
    direction === "ltr" ? "right" : "left"
  );
}
