import { LinearProgress } from "@material/mwc-linear-progress";
import { customElement, property } from "lit/decorators";

@customElement("taui-linear-progress")
// @ts-ignore
export class TauiLinearProgress extends LinearProgress {
  @property({ type: Boolean })
  public active = false;

  @property()
  public alt = "Loading";

  @property({ type: Number })
  public progress = 0;

  @property({ type: Number })
  public buffer = 1;

  // @ts-ignore
  public set indeterminate(_) {
    // just a dummy
  }

  public get indeterminate() {
    return this.active;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-linear-progress": TauiLinearProgress;
  }
}
