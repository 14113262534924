import { TAUIDomEvent } from "../common/dom/fire_event";
import { Constructor, TucanoAdminUI } from "../types";
import { storeState } from "../util/taui-pref-storage";
import { TauiBaseEl } from "./taui-base-mixin";

interface DockSidebarParams {
  dock: TucanoAdminUI["dockedSidebar"];
}

interface DefaultDashboardParams {
  defaultDashboard: TucanoAdminUI["defaultDashboard"];
}

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-dock-sidebar": DockSidebarParams;
    "taui-default-dashboard": DefaultDashboardParams;
  }
  // for add event listener
  interface HTMLElementEventMap {
    "taui-dock-sidebar": TAUIDomEvent<DockSidebarParams>;
    "taui-default-dashboard": TAUIDomEvent<DefaultDashboardParams>;
  }
}

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      this.addEventListener("taui-dock-sidebar", (ev) => {
        this._updateTaui({ dockedSidebar: ev.detail.dock });
        storeState(this.taui!);
      });
      this.addEventListener("taui-default-dashboard", (ev) => {
        this._updateTaui({ defaultDashboard: ev.detail.defaultDashboard });
        storeState(this.taui!);
      });
    }
  };
