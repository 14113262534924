/* eslint-disable lit/prefer-static-styles */
import "@polymer/paper-tooltip/paper-tooltip";
import "@polymer/paper-tabs/paper-tab";
import "@material/mwc-list/mwc-list-item";
import {
  css,
  CSSResultGroup,
  html,
  LitElement,
  PropertyValues,
  TemplateResult,
} from "lit";
import { customElement, property, query, state } from "lit/decorators";
import { repeat } from "lit/directives/repeat";
import { fireEvent } from "../common/dom/fire_event";
import { listenMediaQuery } from "../common/dom/media_query";
import { toggleAttribute } from "../common/dom/toggle_attribute";
import { getInitials } from "../common/string/initials";
import "../components/taui-tabs";
import "../components/taui-drawer";
import "../components/taui-top-app-bar-fixed";
import "../components/taui-button-menu";
import "../components/taui-icon";
import "../components/taui-icon-button";
import "../components/taui-linear-progress";
import "../components/taui-circular-progress";
import { tauiStyle } from "../resources/styles";
import type { TucanoAdminUI } from "../types";
import { generateKeyTab } from "../common/tab/tab";
import { shouldHandleRequestSelectedEvent } from "../common/mwc/handle-request-selected-event";
import { truncStringPortion } from "../common/string/trunc-middle-portion-text";
import { computeRTL, computeRTLDirection } from "../common/util/compute_rtl";
import { removeLaunchScreen } from "../util/launch-screen";
import { deepEqual } from "../common/util/deep-equal";
import { LAZY_LOAD_ROUTES } from "../common/lazy_load_routes";
import { showConfirmationDialog } from "../dialogs/generic/show-dialog-box";
import { TabParams } from "../types";
import { DEFAULT_SELECTED_PANEL } from "../state/connection-mixin";

interface Registry {
  key: string;
  componentName: string;
  id: string;
  object: any;
  subTabSelected: string;
  parentBreadcrumb: any[];
  breadcrumbPath: any[];
  openBackground: boolean;
  forceLoad: boolean;
  allowMultiple?: boolean;
  tabKey: string;

  module: any;
  edited: boolean;
  error: boolean;
}

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-sidebar-open-panel": undefined;
    "taui-tabs-refresh-tab": {
      key: string;
      forceLoad?: boolean;
    };
    "taui-tabs-tab-edited": {
      key: string;
    };
    "taui-tabs-tab-saved": {
      key: string;
    };
    "taui-tabs-tab-error": {
      key: string;
      error?: any;
    };
    "taui-tabs-tab-update-subtab-selected": {
      key: string;
      value: string;
    };
    "taui-tabs-tab-update-tab-session": {
      key: string;
      id: number;
      data: any;
      ctx: HTMLElement;
    };
    "taui-tabs-refresh-breadcrumb-tab": {
      key: string;
      title: string;
      editable: boolean;
      ctx: HTMLElement;
    };
    "taui-tabs-close-tab": {
      key: string;
    };
    "taui-tabs-open-tab": {
      componentName: string;
      object?: any;
      allowMultiple?: boolean;
      parentBreadcrumb?: any[];
      key?: string;
      subTabSelected?: string;
      openBackground?: boolean;
      forceLoad?: boolean;
    };
    "taui-tabs-update-page": {
      component: any;
    };
    "route-changed": string;
    "identity-changed": number;
    "selected-panel-changed": string;
    "tabs-changed": TabParams[];
  }
}

@customElement("taui-app-main")
class TauiAppMain extends LitElement {
  @property({ attribute: false }) public taui!: TucanoAdminUI;

  @property({ type: Boolean, reflect: true }) public narrow!: boolean;

  @property({ type: Boolean, reflect: true }) public rtl = false;

  @state() private _drawerOpen = false;

  @query(".wrapper") private _wrapper!: HTMLElement;

  @property({ type: Array }) public registry: Registry[] = [];

  private _tabs = (registry): Registry[] =>
    registry.filter((el) => el.key !== "dashboard/taui-panel-dashboard");

  constructor() {
    super();
    listenMediaQuery("(max-width: 870px)", (matches) => {
      this.narrow = matches;
    });
  }

  protected render(): TemplateResult {
    const taui = this.taui;
    const sidebarNarrow = this._sidebarNarrow;

    const tabs = this._tabs(this.registry);

    // Style block in render because of the mixin that is not supported
    return html` <taui-drawer
      .type=${sidebarNarrow ? "modal" : ""}
      .open=${sidebarNarrow ? this._drawerOpen : true}
      @MDCDrawer:closed=${this._drawerClosed}
    >
      <taui-sidebar
        .taui=${taui}
        .narrow=${sidebarNarrow}
        .alwaysExpand=${sidebarNarrow || this.taui.dockedSidebar === "docked"}
      ></taui-sidebar>

      <div class="appLayout" slot="appContent">
        <taui-top-app-bar-fixed>
          <taui-icon-button
            .icon=${"fad:bars"}
            @click=${this.toggleSidebar}
            slot="navigationIcon"
          >
          </taui-icon-button>

          <taui-tabs
            slot="title"
            scrollable
            attr-for-selected="tab-key"
            .selected=${this.taui.selectedPanel}
            @iron-activate=${this.handleTabSelected}
            dir=${computeRTLDirection(this.taui!)}
          >
            ${repeat(
              tabs,
              (tab) => tab.key,
              (tab) => {
                const tabTranslated = tab?.object?.name
                  ? tab.object.name
                  : this._getTranslation(this.taui.language, tab.tabKey);

                return html`<paper-tab
                  aria-label=${tabTranslated}
                  tab-key=${tab.key}
                  .tab=${tab}
                >
                  <slot>
                    <div class="tab-content">
                      ${tab.error ? html`<div class="errorTab">!</div>` : ""}
                      ${tab.edited ? html` * ` : ""} ${tabTranslated}
                      <taui-icon
                        .tab=${tab}
                        @click=${this.closeAt}
                        title="Close"
                        .icon=${"fad:xmark"}
                      >
                      </taui-icon>
                    </div>
                  </slot>
                </paper-tab>`;
              }
            )}
          </taui-tabs>

          <div slot="actionItems" class="actionItems">
            ${tabs?.length > 0
              ? html` <taui-button-menu corner="BOTTOM_START" fixed y="4">
                  <taui-icon-button
                    .icon=${"fad:ellipsis-vertical"}
                    slot="trigger"
                  >
                  </taui-icon-button>

                  <mwc-list-item @click=${this._closeTabs} .type=${"all"}>
                    ${this.taui!.localize("ui.components.tabs.close_all_tabs")}
                  </mwc-list-item>
                  <mwc-list-item @click=${this._closeTabs} .type=${"other"}>
                    ${this.taui!.localize(
                      "ui.components.tabs.close_other_tabs"
                    )}
                  </mwc-list-item>
                </taui-button-menu>`
              : ""}
            ${taui.upload.current
              ? html` <div
                  class="uploading"
                  @click=${this._openCreatVodAssetList}
                >
                  <taui-circular-progress
                    alt="Uploading"
                    active
                  ></taui-circular-progress>
                  <div id="details">
                    <span
                      >${this._getCurrentUploadFilename(
                        taui.upload.current,
                        "true"
                      )}</span
                    >
                  </div>
                  <paper-tooltip position="bottom" for="details"
                    >${this._getCurrentUploadFilename(
                      taui.upload.current,
                      "false"
                    )}</paper-tooltip
                  >
                </div>`
              : ""}
            ${!sidebarNarrow
              ? html`
                  ${this.taui.locale?.time_zone !== "browser"
                    ? html` <div class="icon-button">
                        <taui-icon icon="fad:clock"></taui-icon>
                        <paper-tooltip
                          >${this.taui.locale?.time_zone}</paper-tooltip
                        >
                      </div>`
                    : ""}
                `
              : ""}

            <taui-button-menu corner="BOTTOM_START" fixed y="8">
              <div id="profile" class="profile" slot="trigger">
                <span class="initials"
                  >${getInitials(this._getUserDisplayName(taui!.user!))}</span
                >
                ${!sidebarNarrow
                  ? html` <span class="displayName"
                      >${this._getUserDisplayName(taui!.user!)}</span
                    >`
                  : ""}
                <taui-icon
                  id="arrow"
                  icon="fad:chevron-down"
                  disabled
                ></taui-icon>
              </div>
              <mwc-list-item class="userCard" noninteractive>
                <div class="initials">
                  ${getInitials(this._getUserDisplayName(taui!.user!))}
                </div>
                <span class="displayName"
                  >${this._getUserDisplayName(taui!.user!)}</span
                >
              </mwc-list-item>
              <li divider role="separator"></li>
              <mwc-list-item @request-selected=${this.myAccount}>
                <taui-icon
                  icon="fad:user"
                  width="20px"
                  height="20px"
                ></taui-icon>
                ${taui.localize("ui.common.my_account")}
              </mwc-list-item>
              <mwc-list-item @request-selected=${this.myAccountUiConfiguration}>
                <taui-icon
                  icon="fad:user-gear"
                  width="20px"
                  height="20px"
                ></taui-icon>
                ${taui.localize("ui.common.gui")}
              </mwc-list-item>
              <mwc-list-item @request-selected=${this.logout}>
                <taui-icon icon="fad:right-from-bracket"></taui-icon>
                ${taui.localize("ui.common.logout")}
              </mwc-list-item>
            </taui-button-menu>
          </div>
        </taui-top-app-bar-fixed>

        <taui-linear-progress ?active=${taui.loading}></taui-linear-progress>
        <div class="wrapper"></div>
      </div>
    </taui-drawer>`;
  }

  protected firstUpdated() {
    removeLaunchScreen();

    import(/* webpackPreload: true */ "../components/taui-sidebar");

    this.addEventListener("taui-sidebar-open-panel", () => {
      this._drawerOpen = !this._drawerOpen;
    });

    this.addEventListener("taui-tabs-refresh-tab", (ev) => {
      this.setPanelForceLoad(ev.detail.key, ev.detail.forceLoad);
    });

    this.addEventListener("taui-tabs-tab-edited", (ev) => {
      this.setPanelEdited(ev.detail.key);
    });

    this.addEventListener("taui-tabs-tab-saved", (ev) => {
      this.setPanelSaved(ev.detail.key);
    });

    this.addEventListener("taui-tabs-tab-error", (ev) => {
      this.setPanelError(ev.detail.key, ev.detail.error);
    });

    this.addEventListener("taui-tabs-tab-update-subtab-selected", (ev) => {
      this.updateSubTabSelected(ev.detail.key, ev.detail.value);
    });

    this.addEventListener("taui-tabs-tab-update-tab-session", (ev) => {
      this.updateTabSession(
        ev.detail.ctx,
        ev.detail.key,
        ev.detail.id,
        ev.detail.data
      );
    });

    this.addEventListener("taui-tabs-refresh-breadcrumb-tab", (ev) => {
      const key = ev.detail.key;
      const title = ev.detail.title;
      const editable = ev.detail.editable;
      const ctx = ev.detail.ctx;

      this.updatePanelName(key, title, editable);

      ctx.breadcrumbPath[ctx.breadcrumbPath.length - 1].label = title;
      ctx.breadcrumbPath = [...ctx.breadcrumbPath];
    });

    this.addEventListener("taui-tabs-close-tab", (ev) => {
      this.closeTab(ev.detail.key);
    });

    this.addEventListener("taui-tabs-open-tab", (ev) => {
      this.open({
        ...ev.detail,
      });
    });

    this.addEventListener("taui-tabs-update-page", (ev) => {
      if (this._wrapper.lastChild) {
        this._wrapper.removeChild(this._wrapper.lastChild);
      }
      this._wrapper.appendChild(ev.detail.component);
    });

    this.launchStartupModule();
  }

  _getTranslation(_language, name: string) {
    const nameTranslated = this.taui.localize(`panel.${name}`);
    if (nameTranslated) return nameTranslated;
    return name;
  }

  toggleSidebar() {
    if (this._sidebarNarrow) {
      this._drawerOpen = !this._drawerOpen;
    } else {
      fireEvent(this, "taui-dock-sidebar", {
        dock: this.taui.dockedSidebar === "auto" ? "docked" : "auto",
      });
    }
  }

  public willUpdate(changedProps: PropertyValues) {
    super.willUpdate(changedProps);

    if (changedProps.has("taui")) {
      const oldTaui = changedProps.get("taui") as TucanoAdminUI | undefined;
      if (!oldTaui || oldTaui.language !== this.taui.language) {
        this.rtl = computeRTL(this.taui);
      }
      if (oldTaui && oldTaui?.selectedPanel !== this.taui.selectedPanel) {
        this.tabChanged();
      }
    }

    if (changedProps.has("route") && this._sidebarNarrow) {
      this._drawerOpen = false;
    }
  }

  protected updated(changedProps: PropertyValues) {
    super.updated(changedProps);

    toggleAttribute(
      this,
      "expanded",
      this.narrow || this.taui.dockedSidebar !== "auto"
    );

    if (changedProps.has("route") && this._sidebarNarrow) {
      this._drawerOpen = false;
    }

    const oldTaui = changedProps.get("taui") as TucanoAdminUI | undefined;

    if (
      oldTaui &&
      (oldTaui.resources !== this.taui!.resources ||
        oldTaui.panelUrl !== this.taui!.panelUrl ||
        oldTaui.defaultDashboard !== this.taui!.defaultDashboard ||
        oldTaui.dockedSidebar !== this.taui!.dockedSidebar ||
        oldTaui.enableShortcuts !== this.taui!.enableShortcuts ||
        !deepEqual(oldTaui.config, this.taui!.config))
    ) {
      if (this._wrapper.lastChild) {
        this.updatePageEl(this._wrapper.lastChild);
      }
    }

    const oldNarrow = changedProps.get("narrow");

    if (oldNarrow !== undefined && oldNarrow !== this.narrow) {
      if (this._wrapper.lastChild) {
        this.updatePageEl(this._wrapper.lastChild);
      }
    }
  }

  async launchStartupModule() {
    await this.open({
      allowMultiple: false,
      breadcrumbPath: [
        { label: "taui-panel-dashboard" },
        {
          label: "taui-panel-dashboard",
          componentName: "dashboard/taui-panel-dashboard",
        },
      ],
      componentName: "dashboard/taui-panel-dashboard",
      forceLoad: true,
      key: "dashboard/taui-panel-dashboard",
      openBackground: true,
      tabKey: "taui-panel-dashboard",
    });

    if (
      !this.taui.previousIdentity ||
      this.taui.auth.idIdentity !== this.taui.previousIdentity
    ) {
      fireEvent(this as any, "tabs-changed", []);
      fireEvent(this as any, "selected-panel-changed", DEFAULT_SELECTED_PANEL);
      fireEvent(this as any, "identity-changed", this.taui.auth.idIdentity);
    } else if (
      this.taui.config &&
      this.taui.config.rememberTab &&
      this.taui.tabs &&
      this.taui.tabs.length > 0
    ) {
      for (const element of this.taui.tabs) {
        // eslint-disable-next-line no-await-in-loop
        await this.open({
          ...element,
          openBackground: true,
          forceLoad: true,
        });
      }
    }
    this.tabChanged();
  }

  private _getUserDisplayName(user) {
    return user?.displayName
      ? user.displayName
      : user.login === null
      ? user?.ssos?.map((ssos) => ssos.login).join(" / ")
      : user.login;
  }

  myAccount(ev: CustomEvent) {
    if (!shouldHandleRequestSelectedEvent(ev)) {
      return;
    }

    this._openUserTab("details");
  }

  myAccountUiConfiguration(ev: CustomEvent) {
    if (!shouldHandleRequestSelectedEvent(ev)) {
      return;
    }

    this._openUserTab("gui");
  }

  _openUserTab(subTabSelected: string) {
    this.open({
      componentName: "system/user/taui-user",
      openBackground: false,
      object: {
        id: this.taui!.auth.idIdentity,
        subTabSelected: subTabSelected,
      },
      allowMultiple: true,
      parentBreadcrumb: [
        { label: "system" },
        {
          label: "taui-user-list",
          componentName: "system/user/taui-user-list",
        },
      ],
      forceLoad: true,
    });
  }

  logout(ev) {
    if (!shouldHandleRequestSelectedEvent(ev)) {
      return;
    }

    if (navigator.credentials && navigator.credentials.preventSilentAccess) {
      navigator.credentials
        .preventSilentAccess()
        .then(() =>
          // eslint-disable-next-line no-console
          console.log(
            "Silent access prevented (mediation will be required for next credentials.get() call)."
          )
        )
        .catch((err) =>
          // eslint-disable-next-line no-console
          console.error("Error preventing silent access: " + err)
        );
    }

    fireEvent(this, "taui-logout");
  }

  _closeTabs(ev) {
    const type = ev.currentTarget.type;

    this._tabs(this.registry)?.forEach((i) => {
      if (type === "other" && i.key === this.taui.selectedPanel) {
        return;
      }
      this.closeTab(i.key);
    });

    if (type === "all")
      fireEvent(this as any, "selected-panel-changed", DEFAULT_SELECTED_PANEL);
  }

  _openCreatVodAssetList() {
    this.open({
      componentName: "cms/vod/studio/taui-studio",
      openBackground: false,
      object: {
        assetId: this.taui!.upload!.current!.assetId!,
      },
      allowMultiple: true,
    });
  }

  _getCurrentUploadFilename(currentUpload, truncate: string) {
    if (currentUpload && currentUpload.r) {
      const resumableFile = currentUpload.r.files.find(
        (item) => item.file.mediaId === currentUpload.mediaId
      );
      if (resumableFile) {
        const fileName =
          truncate === "true"
            ? truncStringPortion(resumableFile.fileName, 8, 5)
            : resumableFile.fileName;

        return `${this.taui.localize(
          "ui.components.upload.uploading"
        )} ${fileName}`;
      }
    }
    return this.taui.localize("ui.components.upload.upload_in_progress");
  }

  protected updatePageEl(el) {
    const taui = this.taui;
    const narrow = this.narrow;

    el.taui = taui;
    el.narrow = narrow;
  }

  async open(tab: TabParams) {
    const id =
      tab?.object && Object.prototype.hasOwnProperty.call(tab.object, "id")
        ? tab.object.id
        : tab.object;
    const key: string =
      tab?.key ||
      generateKeyTab(tab.componentName, id, tab?.allowMultiple || false);

    const params: TabParams = {
      key,
      componentName: tab?.componentName,
      id,
      object: tab?.object,
      subTabSelected: tab?.subTabSelected,
      parentBreadcrumb: tab?.parentBreadcrumb,
      breadcrumbPath: tab?.breadcrumbPath,
      openBackground: tab?.openBackground ?? false,
      forceLoad: tab?.forceLoad ?? true,
      allowMultiple: tab?.allowMultiple ?? false,
      tabKey: tab?.tabKey,
    };

    let foundElement = this.registry?.find((el) => el.key === key);

    if (!foundElement) {
      let module;

      const elements = tab.componentName.split("/");
      const name = elements[elements.length - 1]?.replace(/[0-9]/g, "");

      if (name) {
        module = name.split("taui");
        if (module.length > 1) {
          module = module[1];
          module = module.replace(/[-]/g, "");
        } else {
          module = undefined;
        }
      }

      if (module && typeof LAZY_LOAD_ROUTES[module] === "function") {
        await LAZY_LOAD_ROUTES[module]()
          .then(async (_element) => {
            const component: any = document.createElement(name);
            foundElement = await this.performLoad(
              component,
              params,
              elements,
              name
            );
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(`Module ${module} failed to load`, error);
            this.closeTab(params.key!);
          });
      } else {
        // eslint-disable-next-line no-console
        console.error(`Module ${module || name} doesn't exist`);
        this.closeTab(params.key!);
      }
    }

    if (foundElement && !params.openBackground) {
      fireEvent(this as any, "selected-panel-changed", params.key);

      if (this.taui.selectedPanel === params.key || params.forceLoad) {
        this.setPanelForceLoad(foundElement.key!, false);

        setTimeout(async () => {
          if (foundElement?.module?.load)
            await foundElement.module.load(
              params.id,
              params.object,
              params.subTabSelected
            );
        }, 10);
      }
    }

    return {
      element: foundElement,
      params: {
        ...params,
        breadcrumbPath: foundElement?.breadcrumbPath || params?.breadcrumbPath,
        tabKey: foundElement?.tabKey || params?.tabKey,
      },
    };
  }

  async performLoad(component, params, elements, name) {
    let tabKey = name;
    let basePath = "";
    let service: any = null;
    let module: any = null;
    let tab: Registry | undefined = this.registry.find(
      (el) => el.key === params.key
    );

    const extractModuleName = (moduleName) => {
      const nameParts = moduleName?.split("/");
      return nameParts && nameParts.length > -1
        ? nameParts[nameParts.length - 1]
        : undefined;
    };

    const findModule = (hiearchy) => {
      let moduleFind: any = null;

      if (hiearchy && hiearchy.items) {
        for (const item of hiearchy.items) {
          moduleFind = item?.items?.find(
            (el) => extractModuleName(el?.path) === name
          );

          if (moduleFind) {
            break;
          } else {
            moduleFind =
              item && item.path && extractModuleName(item?.path) === name
                ? item
                : null;

            if (moduleFind) {
              break;
            } else if (item && item.items) {
              moduleFind = findModule(item);
              if (moduleFind) {
                params.parentBreadcrumb = [
                  {
                    label: service.id,
                  },
                  {
                    label: `${tabKey}-list`,
                    componentName: `${basePath}${moduleFind.path}-list`,
                  },
                ];
                break;
              }
            }
          }
        }
      } else {
        moduleFind = null;
      }
      return moduleFind;
    };

    const getModule = (items, id) => {
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i]) {
            if (items[i].id === id) {
              return items[i];
            }
            const found = getModule(items[i].items, id);
            if (found) return found;
          }
        }
      }
      return undefined;
    };

    if (component) {
      if (!params.breadcrumbPath) {
        if (!params.parentBreadcrumb) {
          for (const elem in this.taui!.structure.services) {
            if (
              Object.prototype.hasOwnProperty.call(
                this.taui!.structure.services,
                elem
              )
            ) {
              service = this.taui!.structure.services[elem];
              basePath = service.base;

              module = findModule(service);

              if (!module) {
                const screenName = extractModuleName(service.base);

                if (screenName === name) {
                  module = service;
                }
              }

              if (module) {
                tabKey =
                  params.object && params.object.name
                    ? params.object.name
                    : module.id;
                break;
              }
            }
          }

          if (module) {
            component.breadcrumbPath = [
              {
                label: service.id,
              },
              {
                label: tabKey,
                componentName: basePath + module.path,
              },
            ];
          }
        } else {
          service = this.taui!.structure.services[elements[0]];

          if (service) module = getModule(service.items, name);

          if (module) {
            tabKey =
              params.object && params.object.name
                ? params.object.name
                : module.id;

            component.breadcrumbPath = params.parentBreadcrumb.concat([
              {
                label: tabKey,
                componentName: params.componentName,
                idObject: params.id,
              },
            ]);
          }
        }
      } else {
        component.breadcrumbPath = params?.breadcrumbPath || [];
      }

      component.modules = [...(module?.items || [])];
      component.tabKey = params.key;

      tab = <Registry>{
        ...params,
        breadcrumbPath: component.breadcrumbPath,
        tabKey,

        module: component,
        edited: false,
        error: false,
      };

      const foundElement = this.registry.find((el) => el.key === params.key);
      if (!foundElement) this.registry = [...(this.registry || []), tab];
    }

    return tab;
  }

  close(key: string) {
    const foundElement = this.registry.find((el) => el.key === key);
    if (foundElement) {
      if (!foundElement.edited) {
        this.closeTab(foundElement.key);
      } else {
        showConfirmationDialog(this as any, {
          text: this.taui!.localize("ui.components.tabs.unsaved_confirm"),
          confirmText: this.taui.localize("ui.common.leave"),
          dismissText: this.taui.localize("ui.common.stay"),
          confirm: () => this.closeTab(foundElement.key),
        });
      }
    }
  }

  closeAt(e) {
    e.stopPropagation();
    e.preventDefault();

    const tab = (e.currentTarget as any).tab;
    if (tab) {
      this.close(tab.key);
    }
  }

  async closeTab(key: string) {
    const foundElement = this.registry.find((el: any) => el.key.includes(key));

    const actualTabSession = this.taui.tabs;

    const removeIndex = actualTabSession
      ?.map((item) => item.key)
      ?.indexOf(foundElement?.key || key);

    if (removeIndex > -1) {
      actualTabSession.splice(removeIndex, 1);
      fireEvent(this as any, "tabs-changed", actualTabSession);
    }

    const currentIdTabSelected = this.registry?.findIndex(
      (reg) => reg?.key === this.taui.selectedPanel
    );

    this.registry = this.registry?.filter((reg) => reg?.key !== key);

    if (this.taui.selectedPanel === key) {
      const previousTabSelected = this.registry?.[currentIdTabSelected - 1];
      fireEvent(
        this as any,
        "selected-panel-changed",
        previousTabSelected?.key
      );
    }
  }

  setPanelForceLoad(tabKey: string, value = true) {
    this.registry =
      this.registry?.map((obj) =>
        obj.key === tabKey ? { ...obj, forceLoad: value } : obj
      ) || [];
  }

  setPanelEdited(key: string) {
    this.registry =
      this.registry?.map((obj) =>
        obj.key === key ? { ...obj, edited: true } : obj
      ) || [];
  }

  setPanelSaved(key: string) {
    this.registry =
      this.registry?.map((obj) =>
        obj.key === key ? { ...obj, edited: false } : obj
      ) || [];
  }

  setPanelError(key: string, error = true) {
    this.registry =
      this.registry?.map((obj) =>
        obj.key === key ? { ...obj, error: error } : obj
      ) || [];
  }

  updatePanelName(key: string, name: string, editable: boolean) {
    if (editable) {
      this.registry =
        this.registry?.map((obj) =>
          obj.key === key
            ? { ...obj, object: { ...obj.object, name }, edited: false }
            : obj
        ) || [];
    }
  }

  updateSubTabSelected(key: string, subTabSelected: string) {
    const tab: any = this.registry.find((el: any) => el.key === key);

    if (tab && subTabSelected) {
      tab.subTabSelected = subTabSelected;
      const actualTabSession = this.taui.tabs;
      const foundElementIndex = actualTabSession.findIndex(
        (el) => el.key === tab.key
      );
      if (foundElementIndex > -1) {
        actualTabSession[foundElementIndex].subTabSelected = subTabSelected;
        fireEvent(this as any, "tabs-changed", actualTabSession);
      }
    }
  }

  async handleTabSelected(ev) {
    const newTabKeySelected = ev.detail.item.getAttribute("tab-key");
    fireEvent(this as any, "selected-panel-changed", newTabKeySelected);
  }

  async tabChanged() {
    const el = this.registry?.find(
      (reg) => reg.key === this.taui.selectedPanel
    );

    if (el) {
      const { element, params } = await this.open({
        ...el,
        openBackground: true,
      });

      if (element) {
        element.module.taui = this.taui;
        element.module.narrow = this.narrow;

        fireEvent(this, "route-changed", params.key);

        fireEvent(this, "taui-tabs-update-page", {
          component: element.module,
        });
        const actualTabSession = this.taui.tabs;

        if (!actualTabSession.some((item) => item.key === params.key)) {
          actualTabSession.push(params);
          fireEvent(this as any, "tabs-changed", actualTabSession);
        }

        if (params.forceLoad) {
          this.setPanelForceLoad(element.key!, false);
          if (element.module.load)
            await element.module.load(
              params.id,
              params.object,
              params.subTabSelected
            );
        }
      }
    }
  }

  updateTabSession(
    ctx: HTMLElement,
    key: string,
    idObject?: number,
    data?: any
  ) {
    const tab = this.registry.find((el: any) => el.key === key);

    if (tab) {
      const actualTabSession = this.taui.tabs;
      const foundElementIndex = actualTabSession.findIndex(
        (el) => el.key === tab.key
      );
      if (foundElementIndex > -1) {
        if (idObject) actualTabSession[foundElementIndex].id = idObject;
        if (data) actualTabSession[foundElementIndex].object = data;
        // @ts-ignore
        ctx.tabKey =
          tab.key =
          actualTabSession[foundElementIndex].key =
            generateKeyTab(
              actualTabSession[foundElementIndex].componentName,
              idObject,
              actualTabSession[foundElementIndex].allowMultiple
            );

        this.registry =
          this.registry?.map((obj) =>
            obj.key === key ? { ...obj, key: tab.key } : obj
          ) || [];

        if (this.taui.selectedPanel === key) {
          fireEvent(this as any, "selected-panel-changed", tab.key);
        }

        fireEvent(this as any, "tabs-changed", actualTabSession);
      }
    }
  }

  private get _sidebarNarrow() {
    return this.narrow || this.taui.dockedSidebar === "always_hidden";
  }

  private _drawerClosed() {
    this._drawerOpen = false;
  }

  static get styles(): CSSResultGroup {
    return [
      tauiStyle,
      css`
        :host {
          color: var(--primary-text-color);
          /* remove the grey tap highlights in iOS on the fullscreen touch targets */
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          --mdc-drawer-width: 56px;
        }

        :host([expanded]) {
          --mdc-drawer-width: calc(256px + env(safe-area-inset-left));
        }

        taui-sidebar {
          /* allow a light tap highlight on the actual interface elements  */
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
        }

        .wrapper {
          position: relative;
          height: calc(100vh - 1px - var(--header-height) - 4px);
          overflow: hidden;
        }

        .appLayout {
          min-height: 100%;
        }

        .appLayout taui-linear-progress {
          position: sticky;
          top: var(--header-height);
          background: var(--app-header-toolbar-background-color);
          width: 100%;
          z-index: 1;
        }

        .appLayout taui-top-app-bar-fixed {
          --taui-top-app-bar-fixed-row-height: calc(
            var(--header-height) - 1px /* border-bottom */
          );
          border-bottom: 1px solid var(--divider-color);
        }

        .appLayout taui-top-app-bar-fixed .icon-button {
          width: 50px;
          min-width: auto;
          height: 100%;
          border: none;
          box-shadow: none;
        }
        .appLayout taui-top-app-bar-fixed .icon-button taui-icon {
          font-size: 2em;
        }

        .appLayout taui-top-app-bar-fixed .profile {
          display: flex;
          align-items: center;
          max-width: 300px;
          height: 100%;
          padding: 0 10px;
          text-align: center;
          font-size: 11pt;
          user-select: none;
          white-space: nowrap;
          border-radius: 3px;
          cursor: pointer;
        }

        .appLayout taui-top-app-bar-fixed .profile .initials {
          width: 40px;
          min-width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          background-color: var(--label-badge-background-color);
          color: var(--label-badge-text-color);
        }

        .appLayout taui-top-app-bar-fixed .profile .displayName {
          margin: 0 0 0 10px;
          font-weight: bold;
          overflow: hidden;
        }

        .appLayout taui-top-app-bar-fixed .profile taui-icon {
          width: 14px;
          margin: 4px 0 4px 10px;
          line-height: 24px !important;
          cursor: pointer;
        }

        .appLayout taui-top-app-bar-fixed mwc-list-item.userCard {
          display: flex;
          align-items: center;
          width: 250px;
          padding: 15px;
          border-radius: 3px 3px 0 0;
          text-align: center;
        }

        .appLayout taui-top-app-bar-fixed mwc-list-item.userCard .initials {
          display: inline-block;
          width: 60px;
          min-width: 60px;
          height: 60px;
          line-height: 60px;
          margin: 0 10px 0 0;
          border-radius: 50%;
          background-color: var(--label-badge-background-color);
          color: var(--label-badge-text-color);
          cursor: auto;
        }

        .appLayout taui-top-app-bar-fixed mwc-list-item.userCard .displayName {
          font-size: 13pt;
        }

        .appLayout taui-top-app-bar-fixed mwc-list-item taui-icon {
          display: inline-flex;
          width: 20px;
          margin: 0 10px;
          padding: 0;
        }

        .appLayout taui-top-app-bar-fixed .actionItems {
          display: flex;
          align-items: center;
        }

        .uploading {
          display: flex;
          align-items: center;
          cursor: pointer;
          border-right: 1px solid var(--divider-color);
        }

        .uploading > taui-circular-progress {
          width: var(--header-height);
          height: var(--header-height);
        }

        .uploading > #details {
          padding-left: 5px;
          width: 100%;
          min-width: 150px;
          font-size: 0.66em;
        }

        taui-tabs {
          height: calc(
            var(--header-height) - 2px /* border-bottom for tab selected */
          );
          width: 100%;
        }
        paper-tab {
          height: calc(100% - 2px);
        }
        paper-tab.iron-selected {
          border-bottom: 2px solid var(--primary-color);
        }

        paper-tab .tab-content {
          display: flex;
          gap: 10px;
        }

        paper-tab .tab-content taui-icon {
          display: flex;
          align-items: center;
          --mdc-icon-button-size: 16px;
          --mdc-icon-size: 16px;
        }

        paper-tab .tab-content .errorTab {
          width: 14px;
          height: 14px;
          line-height: 14px;
          margin-top: 5px;
          padding: 2px;
          background-color: var(--error-color);
          color: var(--text-primary-color);
          border-radius: 25px;
          text-align: center;
        }
      `,
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-app-main": TauiAppMain;
  }
}
