import { TAUIDomEvent, ValidTauiDomEvent } from "../common/dom/fire_event";
import { ProvideTauiElement } from "../mixins/provide-taui-lit-mixin";

declare global {
  // for fire event
  interface TAUIDomEvents {
    "show-side-sheet": ShowSideSheetParams<unknown>;
    "close-side-sheet": undefined;
    "side-sheet-closed": SideSheetClosedParams;
  }
  // for add event listener
  interface HTMLElementEventMap {
    "show-side-sheet": TAUIDomEvent<ShowSideSheetParams<unknown>>;
    "side-sheet-closed": TAUIDomEvent<SideSheetClosedParams>;
  }
}

export interface TauiSideSheet<T = TAUIDomEvents[ValidTauiDomEvent]>
  extends HTMLElement {
  showSideSheet(params: T);
  closeSideSheet?: () => boolean | void;
}

interface ShowSideSheetParams<T> {
  sideSheetTag: keyof HTMLElementTagNameMap;
  sideSheetImport: () => Promise<unknown>;
  sideSheetParams: T;
}

export interface SideSheetClosedParams {
  sideSheet: string;
}

export interface SideSheetState {
  sideSheet: string;
  open: boolean;
  oldState: null | SideSheetState;
  sideSheetParams?: unknown;
}

const LOADED = {};

export const showSideSheet = async (
  element: HTMLElement & ProvideTauiElement,
  root: ShadowRoot | HTMLElement,
  sideSheetTag: string,
  sideSheetParams: unknown,
  sideSheetImport?: () => Promise<unknown>
) => {
  if (!(sideSheetTag in LOADED)) {
    if (!sideSheetImport) {
      if (__DEV__) {
        // eslint-disable-next-line
        console.warn(
          "Asked to show side sheet that's not loaded and can't be imported"
        );
      }
      return;
    }
    LOADED[sideSheetTag] = sideSheetImport().then(() => {
      const sideSheetEl = document.createElement(sideSheetTag) as TauiSideSheet;
      element.provideTaui(sideSheetEl);
      root.appendChild(sideSheetEl);
      return sideSheetEl;
    });
  }

  history.replaceState(
    {
      sideSheet: sideSheetTag,
      open: false,
      oldState:
        history.state?.open && history.state?.sideSheet !== sideSheetTag
          ? history.state
          : null,
    },
    ""
  );
  try {
    history.pushState(
      { sideSheet: sideSheetTag, sideSheetParams: sideSheetParams, open: true },
      ""
    );
  } catch (err) {
    // sideSheetParams could not be cloned, probably contains callback
    history.pushState(
      { sideSheet: sideSheetTag, sideSheetParams: null, open: true },
      ""
    );
  }

  const sideSheetElement = await LOADED[sideSheetTag];
  sideSheetElement.showSideSheet(sideSheetParams);
};

export const replaceSideSheet = () => {
  history.replaceState({ ...history.state, replaced: true }, "");
};

export const closeSideSheet = async (
  sideSheetTag: string
): Promise<boolean> => {
  if (!(sideSheetTag in LOADED)) {
    return true;
  }
  const sideSheetElement = await LOADED[sideSheetTag];
  if (sideSheetElement.closeSideSheet) {
    return sideSheetElement.closeSideSheet() !== false;
  }
  return true;
};

export const makeSideSheetManager = (
  element: HTMLElement & ProvideTauiElement,
  root: ShadowRoot | HTMLElement
) => {
  element.addEventListener(
    "show-side-sheet",
    (e: TAUIDomEvent<ShowSideSheetParams<unknown>>) => {
      const { sideSheetTag, sideSheetImport, sideSheetParams } = e.detail;
      showSideSheet(
        element,
        root,
        sideSheetTag,
        sideSheetParams,
        sideSheetImport
      );
    }
  );
};
